<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-master-data-product-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.masterDataProduct.actions.create') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <template #cell(code)="data">
        <b-link
          :to="{ name: 'apps-master-data-product-detail', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          ( {{ data.item.code }} )  {{ data.item.name}}
        </b-link>
      </template>
    
      <!-- Column: Type -->
      <template #cell(productType)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.product_type }} 
        </span>
      </template>
      
      <!-- Column: Brand -->
      <template #cell(brandId)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.brand.name }}
        </span>
      </template>
      
      <!-- Column: Category -->
      <template #cell(categoryId)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.category.name }}
        </span>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner
} from 'bootstrap-vue'

// import useList from './useList'
import useListTable from '@/comp-functions/useListTable'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner
  },
  setup () {
    // Table Handlers
    const tableColumns = [
      { key: 'code', label: 'Product', sortable: true },
      { key: 'productType', label: 'Type' },
      { key: 'brandId', label: 'Brand' },
      { key: 'categoryId', label: 'Category' }
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'code',
      'name',
      'description',
      'brand'
    ]

    return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/product'
      })
    }
  }
}
</script>
